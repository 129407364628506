import React from 'react'
import CourseCard from './CourseCard'
function PopularCourse() {
    return (
        <>
        <div>
            <p className='text-center text-blue-600'>Popular Courses</p>
            <h1 className='text-center font-medium text-5xl'>Featured Courses</h1>
        </div>
        <div className='max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8'>

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {/* Here we can use map function to fetch data from backend because no backend here so i have to do manually  */}
              <CourseCard
                courseName="React Bootcamp"
                price="55.00"
                imgURL="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                ratting="4.6"
                level="Advanced"
                purchaseNo="10"
                />
              <CourseCard
                courseName="Vue Bootcamp"
                price="10.00"
                imgURL="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dGVhbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                ratting="3.6"
                level="Modarate"
                purchaseNo="1011"
                />
                <CourseCard
                courseName="React Bootcamp"
                price="55.00"
                imgURL="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                ratting="4.6"
                level="Advanced"
                purchaseNo="10"
                />
              <CourseCard
                courseName="Vue Bootcamp"
                price="10.00"
                imgURL="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dGVhbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                ratting="3.6"
                level="Modarate"
                purchaseNo="1011"
                />
              </div>
                </div>
            
        </>
    )
}

export default PopularCourse
