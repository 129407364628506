import React from "react";

function Pannel() {
  return (
    <section className="bg-blue-600 w-full h-72 block">
       <h1 className="text-white font-medium">  
         <a href="/">Home</a> {"<"} <a href="/" className="text-gray-400">Courses</a>
         </h1> 
      <div className="md:flex">
        <div className="text-white m-2">
          <h1 className=" text-7xl lg:m-10 lg:text-9xl font-bold sm:text-7xl">Courses</h1>
        </div>
        <div className="text-white mt-5 lg:mt-14 ">
          <p className="p-2 lg:text-3xl font-medium lg:font-medium md:font-medium md:text-3xl sm:text-2xl sm:font-medium">
            Naruto Edu is one of world best virtual learning network eLearning
          </p>
          <p className="text-gray-400 font-medium px-2">You can be Your own Guiding start with our help</p>
      <button className=" m-2 px-3 py-2 rounded-md text-sm font-medium text-blue-700 bg-white"> 🏈 Discover More</button>
        </div>
      </div>
    </section>
  );
}
export default Pannel;
