import './App.css';
import Navbar from './components/NavBar'
import Pannel from './components/Pannel';
import CourseNav from './components/CourseNav';
import Courses from './components/Courses';
function App() {
  return (
    <>
    <Navbar/>
    <Pannel/>
    <CourseNav/>
    <Courses/>
    </>
  );
}

export default App;
