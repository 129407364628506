import React from "react";
import {FaCartPlus} from 'react-icons/fa'
function NavBar() {
  return (
    <>
      <nav className="bg-white-800">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-2xl" title="here will come logo">Logo</h1>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  <a
                    href="/"
                    className=" text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
                    aria-current="page"
                  >
                    Category
                  </a>
                   <input type="text" placeholder="Search course here" className="placeholder:italic placeholder:text-gray-400 block bg-white w-full border-2 border-blue-700 rounded-3xl py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-blue-900 focus:ring-blue-500 focus:ring-1 sm:text-sm"/>
                  <a
                    href="/"
                    className="text-gray-300  hover:text-blue-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    courses
                  </a>

                  <a
                    href="/"
                    className="text-gray-300 hover:text-blue-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Blog
                  </a>
                </div>
              </div>
            </div>
            <a className="" href="/">
            <FaCartPlus color="blue"/>
            </a>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
             <button className="bg-blue-900 text-white px-3 py-2 rounded-md text-sm font-medium">Try for free</button>
            </div>
          </div>
        </div>

        <div className="sm:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <a
              href="/"
              className="bg-blue-700 text-white block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
            >
              Category
            </a>

            <a
              href="/"
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Team
            </a>

            <a
              href="/"
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Projects
            </a>

            <a
              href="/"
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Calendar
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
