import React from "react";

function CourseNav() {
  return (
    <div className="w-full md:flex lg:flex bg-white lg:h-16 shadow">
      <a href="/" className="md:ml-0 lg:ml-32">
        <div className="p-5">
          <p>Desgin</p>
        </div>
      </a>
      <a href="/">
        <div className="p-5">
          <p>Web Development</p>
        </div>
      </a>
      <a href="/">
        <div className="p-5">
          <p>Mobile Development</p>
        </div>
      </a>
      <a href="/">
        <div className="p-5">
          <p>Programming Language</p>
        </div>
      </a>
      <a href="/">
        <div className="p-5">
          <p>Game development</p>
        </div>
      </a>
      <a href="/">
        <div className="p-5">
          <p>Database Administration</p>
        </div>
      </a>
    </div>
  );
}

export default CourseNav;
