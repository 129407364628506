import React from "react";
import {FaCartPlus} from 'react-icons/fa'
import {FaStar} from 'react-icons/fa'
import {BsFillPeopleFill} from 'react-icons/bs'
import {GiPunchingBag} from 'react-icons/gi'
import {} from 'react-icons/fa'
import {} from 'react-icons/fa'
function CourseCard({courseName, price , imgURL, ratting, purchaseNo, level}) {
  return (
    <div className="shadow rounded-lg">
      <a href="/" className="group">
        <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-t-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img
            src={imgURL}
            alt="product-img"
            className="w-full h-full object-center object-cover group-hover:opacity-75"
          />
        </div>
        <h3 className="m-4 text-lg font-bold text-gray-700">{courseName}</h3>
        <div className="flex m-5">
        <GiPunchingBag className="mx-3 m-1" color="blue"/> {level}
        <BsFillPeopleFill className="mx-3 m-1" color="black"/> {purchaseNo} 
        <FaStar className="mx-3 m-1" color="gold"/> {ratting}
        </div>
        <hr />
      </a>
        <div className="flex h-12">

        <p className="m-3 text-lg  text-gray-900 font-bold">${price}</p>
        <a href="/">

        <p className="text-blue-700 m-3 ml-16 inline-flex"><FaCartPlus className="m-1" color="blue"/> Add to card </p>
        </a>
        </div>
    </div>
  );
}

export default CourseCard;
